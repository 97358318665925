import React from 'react'
import ReactToolTip from 'react-tooltip'

type buttonProps = {
  /** Text displayed in Button */
  value: string
  /** Called when Button is clicked */
  onClick?: () => void
  /** Disable Button */
  disabled?: boolean
  /** Additional classes applied to input for applying css */
  extraClass?: string
  /** Identifier applied to input and also used for applying tooltip */
  id?: string
  /** Type applied to internal input component */
  type?: 'button' | 'submit'
  /** Used for creating selectors for testing */
  dataTest?: string
  /** Displays text in tooltip using react-tooltip */
  tooltip?: string
}

/** Button component with optional tooltip. */
const Button = ({
  value,
  onClick,
  disabled = false,
  extraClass = '',
  id = '',
  type = 'button',
  dataTest = '',
  tooltip = '',
}: buttonProps): JSX.Element => (
  <span
    data-tip
    data-for={id || 'tooltip-msg'}
    data-class='h-tooltip'
    data-delay-show={500}
    data-border='true'
    data-effect='solid'
    data-place='right'
  >
    <input
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`h-btn ${extraClass}`}
      id={id}
      value={value}
      data-test={dataTest}
      data-testid={dataTest}
    />
    {tooltip && (
      <ReactToolTip id={id || 'tooltip-msg'} type='info' effect='float'>
        <span className={'h-tooltip-content'} data-testid='tooltip-content'>
          {tooltip}
        </span>
      </ReactToolTip>
    )}
  </span>
)

export default Button
